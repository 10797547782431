<template>
  <div>
    <b-overlay :show="loading">
      <b-card class="overflow-hidden" bg-variant="primary">
        <b-row no-gutters>
          <b-col md="6">
            <div class="">
              <h3 class="text-white">
                <strong>{{ data?.jadwal_name }}</strong>
              </h3>
              <span class="d-block text-white">Paket: {{ data?.name }}</span>
              <div class="d-flex align-items-center mt-2">
                <div class="d-flex align-items-center text-body mr-5">
                  <feather-icon icon="ClockIcon" class="mr-50 text-white" />
                  <small class="font-weight-bold text-white">Waktu Mulai</small>
                </div>
                <div class="d-flex align-items-center text-body ml-2">
                  <feather-icon icon="ClockIcon" class="mr-50 text-white" />
                  <small class="font-weight-bold text-white"
                    >Waktu Berakhir</small
                  >
                </div>
              </div>
              <div class="d-flex align-items-center mt-1 mb-2">
                <div class="d-flex align-items-center text-body">
                  <small
                    class="font-weight-bold text-white"
                    v-if="data?.info_start"
                    >{{ getTanggal(data?.info_start) }} WIB</small
                  >
                </div>
                <div class="d-flex align-items-center text-body ml-1">
                  <small
                    class="font-weight-bold text-white"
                    v-if="data?.info_end"
                    >{{ getTanggal(data?.info_end) }} WIB</small
                  >
                </div>
              </div>

              <b-button
                size="sm"
                @click.prevent="viewSelectedMajors"
                variant="light"
                v-if="thereRumpunAndMajors"
              >
                <div class="d-flex align-items-center">
                  <feather-icon icon="FileIcon" class="text-dark" size="12" />
                  <span style="margin-left: 5px"
                    >Jurusan Pilihan Anda ({{ selectedMajors.length }})</span
                  >
                </div>
              </b-button>
              <b-button
                size="sm"
                @click.prevent="viewSelectedSchool"
                variant="light"
                v-if="thereSelectedSchool"
                class="mb-2"
              >
                <div class="d-flex align-items-center">
                  <feather-icon icon="InfoIcon" class="text-dark" size="12" />
                  <span style="margin-left: 5px">Sekolah Pilihan Anda</span>
                </div>
              </b-button>
            </div>
          </b-col>
          <b-col md="6">
            <b-card>
              <b-row class="">
                <b-col>
                  <b-media vertical-align="top" class="mb-2">
                    <template #aside>
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        size="sm"
                        variant="dark"
                        class="btn-icon rounded-circle"
                        disabled
                      >
                        <feather-icon icon="UserIcon" />
                      </b-button>
                    </template>
                    <span>Peserta</span> <br />
                    <b>{{ data.sum_quota }}/{{ data.max_quota }}</b>
                  </b-media>
                </b-col>
                <b-col>
                  <b-media vertical-align="top" class="mb-2">
                    <template #aside>
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        size="sm"
                        variant="dark"
                        class="btn-icon rounded-circle"
                        disabled
                      >
                        <feather-icon icon="ClockIcon" />
                      </b-button>
                    </template>
                    <span>Total Waktu</span> <br />
                    <b> {{ totalTimer }} menit</b>
                  </b-media>
                </b-col>
                <b-col>
                  <b-media vertical-align="top" class="mb-2">
                    <template #aside>
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        size="sm"
                        variant="dark"
                        class="btn-icon rounded-circle"
                        disabled
                      >
                        <feather-icon icon="FileTextIcon" />
                      </b-button>
                    </template>
                    <span>Total Soal</span> <br />
                    <b>{{ totalSoal }} Soal</b>
                  </b-media>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-card>
      <b-card
        :header="'Subtest (' + data.sum_subtest + ')'"
        header-text-variant="black"
      >
        <b-card-body>
          <div class="" v-if="dataSubtes.length > 0">
            <div class="" v-for="(data, i) in dataSubtes" :key="i">
              <app-collapse v-if="data.name && data.items">
                <app-collapse-item :title="data.name">
                  <div class="mt-2" v-for="(item, i) in data.items" :key="i">
                    <b-card no-body class="border py-2 px-0">
                      <b-card-body>
                        <div
                          class="d-flex flex-column flex-sm-row justify-content-between align-items-center"
                        >
                          <div class="mb-2">
                            <h4>
                              <b> {{ item.nama_ujian }}</b>
                            </h4>
                            <span v-if="item.jadwal_id != null">
                              <span
                                v-if="item.hasil && item.status == 'complete'"
                              >
                                <feather-icon
                                  icon="CalendarIcon"
                                  class="mr-50"
                                />
                                <small
                                  class="font-weight-bold"
                                  v-if="item?.start_time && item?.end_time"
                                  >{{ getTanggal(item.start_time) }} WIB s/d
                                  {{ getTanggal(item.end_time) }} WIB
                                </small>
                                <div class="mt-1">
                                  <feather-icon
                                    icon="ClockIcon"
                                    class="mr-50 text-success"
                                  />
                                  <small class="font-weight-bold"
                                    >{{ item.timer }} Menit</small
                                  >
                                  <feather-icon
                                    icon="FileTextIcon"
                                    class="mr-50 ml-2 text-success"
                                  />
                                  <small class="font-weight-bold"
                                    >{{
                                      item?.ujian_data?.sum_soal
                                        ? item?.ujian_data?.sum_soal
                                        : 0
                                    }}
                                    Soal</small
                                  >
                                </div>
                              </span>
                              <span v-else>
                                <feather-icon
                                  icon="CalendarIcon"
                                  class="mr-50"
                                />
                                <small class="font-weight-bold"
                                  >{{ getTanggal(item.start_time) }} WIB s/d
                                  {{ getTanggal(item.end_time) }} WIB
                                </small>
                                <div class="">
                                  <feather-icon
                                    icon="ClockIcon"
                                    class="mr-50"
                                  />
                                  <small class="font-weight-bold"
                                    >{{ item.timer }} Menit</small
                                  >
                                  <feather-icon
                                    icon="FileTextIcon"
                                    class="mr-50 ml-2"
                                  />
                                  <small class="font-weight-bold"
                                    >{{
                                      item?.ujian_data?.sum_soal
                                        ? item?.ujian_data?.sum_soal
                                        : 0
                                    }}
                                    Soal</small
                                  >
                                </div>
                              </span>
                            </span>
                            <span v-else>
                              <i class="text-danger">Jadwal Belum ditentukan</i>
                            </span>
                          </div>
                          <div class="d-flex align-items-center text-body">
                            <span v-if="item.jadwal_id">
                              <b-button
                                v-if="item.hasil && item.status == 'complete'"
                                variant="outline-success"
                                >Selesai</b-button
                              >
                              <b-button
                                v-else-if="!item.in_schedule"
                                variant="outline-secondary"
                                disabled
                                >Tidak Masuk Jadwal</b-button
                              >
                              <b-button
                                v-else-if="!item?.ujian_data?.soal_ids"
                                variant="outline-secondary"
                                disabled
                                >Soal Kosong</b-button
                              >
                              <b-button
                                v-else
                                variant="outline-primary"
                                @click="mulaiUjian(item)"
                              >
                                Mulai
                              </b-button>
                            </span>
                            <span v-else>
                              <b-button variant="primary disabled" disabled>
                                Jadwal Belum Ditentukan
                              </b-button>
                            </span>
                            <!-- <b-button variant="outline-primary" @click="mulaiUjian(data)" v-if="data.in_schedule == true"> </b-button>
                      <b-button variant="outline-primary" @click="mulaiUjian(data)" v-else disabled> Mulai </b-button> -->
                          </div>
                        </div>
                      </b-card-body>
                    </b-card>
                  </div>
                </app-collapse-item>
              </app-collapse>
              <b-card no-body class="border" v-else>
                <b-card-body>
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <div class="align-items-center text-body">
                      <h4>
                        <b> {{ data.nama_ujian }}</b>
                      </h4>
                      <span v-if="data.jadwal_id != null">
                        <span v-if="data.status == 'complete'">
                          <feather-icon icon="CalendarIcon" class="mr-50" />
                          <small
                            class="font-weight-bold"
                            v-if="data?.start_time && data?.end_time"
                            >{{ getTanggal(data.start_time) }} WIB s/d
                            {{ getTanggal(data.end_time) }} WIB
                          </small>
                          <div class="">
                            <feather-icon
                              icon="ClockIcon"
                              class="mr-50 text-success"
                            />
                            <small class="font-weight-bold">
                              {{ data.timer }} Menit
                            </small>
                            <feather-icon
                              icon="FileTextIcon"
                              class="mr-50 ml-2 text-success"
                            />
                            <small class="font-weight-bold">
                              {{
                                data?.ujian_data?.sum_soal
                                  ? data?.ujian_data?.sum_soal
                                  : 0
                              }}
                              Soal
                            </small>
                          </div>
                        </span>
                        <span v-else>
                          <feather-icon icon="CalendarIcon" class="mr-50" />
                          <small
                            class="font-weight-bold"
                            v-if="data?.start_time && data?.end_time"
                            >{{ getTanggal(data.start_time) }} WIB s/d
                            {{ getTanggal(data.end_time) }} WIB
                          </small>
                          <div class="">
                            <feather-icon icon="ClockIcon" class="mr-50" />
                            <small class="font-weight-bold"
                              >{{ data.timer }} Menit</small
                            >
                            <feather-icon
                              icon="FileTextIcon"
                              class="mr-50 ml-2"
                            />
                            <small class="font-weight-bold"
                              >{{
                                data?.ujian_data?.sum_soal
                                  ? data?.ujian_data?.sum_soal
                                  : 0
                              }}
                              Soal</small
                            >
                          </div>
                        </span>
                      </span>
                      <span v-else>
                        <i class="text-danger">Jadwal Belum ditentukan</i>
                      </span>
                    </div>
                    <div class="d-flex align-items-center text-body">
                      <span v-if="data.jadwal_id != null">
                        <b-button
                          v-if="data.status == 'complete'"
                          variant="outline-success"
                          >Selesai</b-button
                        >
                        <b-button
                          v-else
                          variant="outline-primary"
                          @click="mulaiUjian(data)"
                        >
                          Mulai
                        </b-button>
                      </span>
                      <span v-else>
                        <b-button variant="primary disabled" disabled>
                          Jadwal Belum Ditentukan
                        </b-button>
                      </span>
                      <!-- <b-button variant="outline-primary" @click="mulaiUjian(data)" v-if="data.in_schedule == true"> Mulai </b-button>
                      <b-button variant="outline-primary" @click="mulaiUjian(data)" v-else disabled> Mulai </b-button> -->
                    </div>
                  </div>
                </b-card-body>
              </b-card>
            </div>
          </div>
          <div v-else>
            <h5 class="text-danger text-center mt-2">Tidak ada Subtest</h5>
          </div>
          <center>
            <span>
              <b-button
                v-if="is_lihat_hasil == true"
                variant="outline-primary"
                @click="lihatHasil()"
              >
                Lihat Hasil
              </b-button>
            </span>
          </center>
        </b-card-body>
      </b-card>
    </b-overlay>

    <!-- Modal View Selected Rumpun dan Jurusan -->
    <b-modal no-close-on-backdrop no-close-on-esc id="view-selected-major">
      <section v-if="selectedSchool">
        <h5><strong>Pilihan Sekolah Kedinasan:</strong></h5>
        <p>{{ selectedSchool?.school?.name }}</p>
      </section>
      <section v-if="selectedRumpun" class="mb-2">
        <h5><strong>Pilihan Rumpun:</strong></h5>
        <p>{{ selectedRumpun?.rumpun?.name }}</p>
      </section>
      <section v-if="selectedMajors.length > 0" class="mb-2">
        <h5>
          <strong>Pilihan Jurusan ({{ selectedMajors.length }})</strong>
        </h5>
        <table class="table table-bordered table-responsive w-100">
          <tr>
            <th>No</th>
            <th>Universitas</th>
            <th>Jurusan</th>
          </tr>
          <tr v-for="(item, i) in selectedMajors" :key="i">
            <td>{{ i + 1 }}</td>
            <td>
              <strong>{{ item?.school?.name }}</strong>
            </td>
            <td>
              <strong
                ><i>{{ item?.major?.name }}</i></strong
              >
            </td>
          </tr>
        </table>
      </section>

      <template #modal-footer>
        <b-button @click.prevent="$bvModal.hide('view-selected-major')"
          >OK</b-button
        >
      </template>
    </b-modal>
    <!-- / -->
  </div>
</template>

<script>
import {
  BOverlay,
  BCollapse,
  BButton,
  VBToggle,
  BCard,
  BCardHeader,
  BCardBody,
  BCardTitle,
  BCardImg,
  BImg,
  BBadge,
  BRow,
  BCol,
  BMedia,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BOverlay,
    BButton,
    BCard,
    BCardHeader,
    BCollapse,
    BCardBody,
    BCardTitle,
    BCardImg,
    BImg,
    BBadge,
    BRow,
    BCol,
    BMedia,
    AppCollapse,
    AppCollapseItem,
  },
  directives: {
    "b-toggle": VBToggle,
    Ripple,
  },
  computed: {
    tryoutDetail() {
      return this.$store.state.tryout.detail;
    },
    paketMustChooseRumpun() {
      return (
        this.tryoutDetail?.is_contain_rumpun == 1 &&
        ["mandiri", "utbk"].includes(this.tryoutDetail?.rasionalisasi_type)
      );
    },
    paketMustChooseSchool() {
      return (
        this.tryoutDetail?.is_contain_rumpun == 1 &&
        ["kedinasan", "bintara"].includes(this.tryoutDetail?.rasionalisasi_type)
      );
    },
    thereRumpunAndMajors() {
      if (
        !this.selectedRumpun ||
        this.selectedMajors.length < 1 ||
        this.tryoutDetail?.is_contain_rumpun == 0
      ) {
        return false;
      }

      return true;
    },
    thereSelectedSchool() {
      if (!this.selectedSchool || this.tryoutDetail?.is_contain_rumpun == 0) {
        return false;
      }

      return true;
    },
  },
  data() {
    return {
      totalTimer: 0,
      totalSoal: 0,
      data: {},
      dataSubtes: [],
      jadwal_id: null,
      hasilRasionalisasi: {},
      is_lihat_hasil: false,
      loading: false,
      selectedRumpun: null,
      selectedSchool: null,
      selectedMajors: [],
    };
  },
  methods: {
    viewSelectedMajors() {
      if (!this.thereRumpunAndMajors) {
        return false;
      }

      this.$bvModal.show("view-selected-major");
    },
    viewSelectedSchool() {
      if (!this.thereSelectedSchool) {
        return false;
      }

      this.$bvModal.show("view-selected-major");
    },
    getData() {
      this.loading = true;
      let id = this.tryoutDetail.id;
      let params = {
        id,
      };
      if (this.$route.query?.j) {
        params.jadwal_id = this.$route.query.j;
      }
      this.$store
        .dispatch("paketTryout/detail", params)
        .then((res) => {
          this.loading = false;
          this.data = res.data;
          this.$store.commit("paketTryout/SET_DETAIL", res.data);
          this.getSubtes();
          if (this.paketMustChooseRumpun) {
            this.getSelectedRumpun();
            this.getSelectedMajors();
          } else if (this.paketMustChooseSchool) {
            this.getSelectedSchool();
          }
        })
        .catch((err) => {
          this.loading = false;
          this.displayError(err);
          return false;
        });
    },
    getSubtes() {
      let params = {
        paket_id: this.data.id,
      };

      if (this.$route.query?.j) {
        params.jadwal_id = this.$route.query?.j;
      }
      this.$store
        .dispatch("ujian/subtesPaket", params)
        .then((res) => {
          this.dataSubtes = res.data.data;

          this.totalSoal = this.getTotalSoal(this.dataSubtes);
          if (this.data.is_contain_subcat == 1) {
            // jika ada sub kategori yg items nya 0, maka buang saja
            const filteredSubtest = this.dataSubtes.filter(
              (subcat) => subcat?.items.length > 0
            );
            this.dataSubtes = filteredSubtest;
            const jadwalId = this.dataSubtes.reduce((jadwal_id, sb) => {
              const findJadwal = sb.items.find((subtest) => {
                return subtest.jadwal_id != null;
              });
              jadwal_id = findJadwal ? findJadwal.jadwal_id : null;

              return jadwal_id;
            }, null);
            this.jadwal_id = jadwalId;
          } else {
            const jadwalId = this.dataSubtes.find(
              (subtes) => subtes.jadwal_id != null
            );
            this.jadwal_id = jadwalId ? jadwalId.jadwal_id : null;
          }

          this.totalTimer = this.getTotalTimer(this.dataSubtes);
          if (this.data.is_contain_subcat == 1) {
            const sumSubtes = this.dataSubtes.reduce((total, itemSubtes) => {
              if (itemSubtes.items && itemSubtes.items.length > 0) {
                total += itemSubtes.items.length;
              }
              return total;
            }, 0);

            let sumCompleteSubtes = 0;
            this.dataSubtes.forEach((itemSubtes, i) => {
              if (itemSubtes.items) {
                sumCompleteSubtes += itemSubtes.items.reduce((total, item) => {
                  if (item.status && item.status == "complete") {
                    total += 1;
                  }
                  return total;
                }, 0);
              }
            });
            this.is_lihat_hasil = sumSubtes == sumCompleteSubtes;
          } else {
            let sumSubtes = this.dataSubtes.length;
            let sumCompleteSubtes = 0;
            sumCompleteSubtes += this.dataSubtes.reduce((total, item) => {
              if (item.status && item.status == "complete") {
                total += 1;
              }
              return total;
            }, 0);
            this.is_lihat_hasil = sumSubtes == sumCompleteSubtes;
          }
        })
        .catch((err) => {
          this.displayError(err);
        });
    },
    getTotalSoal(subtests) {
      let totalSoal = 0;
      subtests.forEach((item) => {
        if (item.items && item.items.length > 0) {
          const totalSoalSubtest = item.items.reduce((total, item) => {
            if (item?.ujian_data?.soal_ids) {
              const arraySoal = item?.ujian_data?.soal_ids.split(",");
              total += arraySoal.length;
            }
            return total;
          }, 0);
          totalSoal += totalSoalSubtest;
        } else {
          if (item?.ujian_data?.soal_ids) {
            const arraySoal = item?.ujian_data?.soal_ids.split(",");
            totalSoal += arraySoal.length;
          }
        }
      });

      return totalSoal;
    },
    getTotalTimer(subtests) {
      let totalTimer = 0;
      subtests.forEach((item) => {
        if (item.items && item.items.length > 0) {
          const totalTimerSubtest = item.items.reduce(
            (total, subtest) => (total += parseInt(subtest.timer)),
            0
          );
          totalTimer += totalTimerSubtest;
        } else {
          totalTimer += item.timer ? parseInt(item.timer) : 0;
        }
      });

      return totalTimer;
    },
    mulaiUjian(dataUjian) {
      this.loading = true;
      let payload = {
        paket_id: this.tryoutDetail.id,
        jadwal_id: dataUjian.jadwal_id,
        ujian_id: dataUjian.ujian_id,
      };
      this.$store
        .dispatch("ujian/mulaiUjian", payload)
        .then((res) => {
          this.loading = false;
          this.$store.commit("ujian/SET_DETAIL_UJIAN", dataUjian);
          this.$store.commit("ujian/SET_CURRENT_UJIAN", res.data);
          this.$store.commit("ujian/SET_CURRENT_KOLOM", null);
          this.$store.commit("ujian/SET_CURRENT_SOALS", null);
          this.$store.commit("ujian/SET_CURRENT_NO_KOLOM", null);
          this.$store.commit("ujian/SET_CURRENT_NO", null);
          this.$store.commit("ujian/SET_CURRENT_SOAL", null);

          localStorage.removeItem("endUjian");
          const isUjianPauli = dataUjian?.ujian_data?.mode == "pauli";
          if (isUjianPauli) {
            localStorage.removeItem("endtime");
            // set current data kolom
            const dataKolom = res.data.user_answer.map((kolom) => {
              kolom.sub_soals = kolom.sub_soals.map((soal) => {
                soal.is_complete = [0, 1].includes(soal.answer) ? true : false;
                return soal;
              });

              return kolom;
            });
            this.$store.commit("ujian/SET_DATA_KOLOM", dataKolom);
          }
          window.location = `/main-ujian?j=${this.$route.query.j}`;
        })
        .catch((err) => {
          this.loading = false;
          this.displayError(err);
          return false;
        });
    },
    getHasil() {
      let params = {
        paket_id: this.data.id,
        jadwal_id: this.jadwal_id,
      };
      this.$store
        .dispatch("rasionalisasi/index", params)
        .then((res) => {
          this.hasilRasionalisasi = res.data;
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error `,
              icon: "XIcon",
              variant: "danger",
              text: err.response.data.message,
            },
          });
        });
    },
    lihatHasil() {
      // this.$store.commit(
      //   "ujian/SET_CURRENT_HASIL_RASIONALISASI",
      //   this.hasilRasionalisasi
      // );
      this.$store.commit("paketTryout/SET_DETAIL", this.data);
      this.$router.push({
        name: "hasil-rasionalisasi",
        query: { p: this.data?.id, j: this.jadwal_id },
      });
    },
    async getSelectedRumpun() {
      const params = {
        paket_id: this.data?.id,
        user_id: this.user?.id,
        jadwal_id: this.$route.query?.j,
      };
      let response = await this.$store.dispatch("rumpun/rumpunPaket", params);

      this.selectedRumpun =
        response.data?.data?.length > 0 ? response.data?.data[0] : null;
    },
    async getSelectedMajors() {
      // check selected jurusan
      const paramsMajor = {
        paket_id: this.data?.id,
        user_id: this.user?.id,
        jadwal_id: this.$route.query?.j,
        order: "asc",
      };
      let response = await this.$store.dispatch(
        "jurusan/jurusanPaket",
        paramsMajor
      );
      this.selectedMajors = response.data?.data ? response.data?.data : [];
    },
    async getSelectedSchool() {
      // check selected jurusan
      const paramsMajor = {
        paket_id: this.data?.id,
        user_id: this.user?.id,
        jadwal_id: this.$route.query?.j,
      };
      let response = await this.$store.dispatch(
        "jurusan/jurusanPaket",
        paramsMajor
      );
      this.selectedSchool =
        response.data?.data?.length > 0 ? response.data?.data[0] : null;
    },
  },
  created() {
    if (this.tryoutDetail.name == undefined || !this.$route.query.j) {
      this.$router.go(-1);
    }
    this.getData();
  },
};
</script>
